import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "admins",
        component: {template: "<router-view></router-view>"},
        meta: {auth: true, title: "Главная"},
        children: [
            {
                path: "users",
                component: {template: "<router-view></router-view>"},
                redirect: "users/list",
                meta: {title: "Пользователи", dontDisplayChildren: true},
                children: [
                    {
                        path: "list",
                        component: () => import("./../components/Users/List"),
                        meta: {title: "Список"},
                    },
                    {
                        path: ":id",
                        component: {template: "<router-view></router-view>"},
                        redirect: ":id/view",
                        meta: {title: "Пользователь"},
                        children: [
                            {
                                path: "view",
                                name: "users.view",
                                component: () => import("./../components/Users/View"),
                                meta: {title: "Просмотр"},
                            },
                            {
                                path: "edit",
                                component: () => import("./../components/Users/Edit"),
                                meta: {title: "Редактирование"},
                            },
                        ],
                    },
                ],
            },
            {
                path: "contracts",
                component: {template: "<router-view></router-view>"},
                redirect: "contracts/list",
                meta: {title: "Контракты", dontDisplayChildren: true},
                children: [
                    {
                        path: "list",
                        name: "contracts.list",
                        component: () => import("./../components/Contracts/List"),
                        meta: {title: "Список"},
                    },
                    {
                        path: ":id",
                        component: {template: "<router-view></router-view>"},
                        redirect: ":id/view",
                        meta: {title: "Контракт"},
                        children: [
                            {
                                path: "view",
                                name: "contracts.view",
                                component: () => import("./../components/Contracts/View"),
                                meta: {title: "Просмотр"},
                            },
                        ],
                    },
                ],
            },
            {
                path: "payments",
                component: {template: "<router-view></router-view>"},
                redirect: "payments/list",
                meta: {title: "Платежи", dontDisplayChildren: true},
                children: [
                    {
                        path: "list",
                        name: "payments.list",
                        component: () => import("./../components/Payments/List"),
                        meta: {title: "Список"},
                    },
                    {
                        path: ":id",
                        component: {template: "<router-view></router-view>"},
                        redirect: ":id/view",
                        meta: {title: "Платеж"},
                        children: [
                            {
                                path: "view",
                                component: () => import("./../components/Payments/View"),
                                meta: {title: "Просмотр"},
                            },
                        ],
                    },
                ],
            },
            {
                path: "admins",
                component: {template: "<router-view></router-view>"},
                redirect: "admins/list",
                meta: {title: "Администраторы", dontDisplayChildren: true},
                children: [
                    {
                        path: "list",
                        component: () => import("./../components/Admins/List"),
                        meta: {title: "Список"},
                    },
                    {
                        path: "add",
                        component: () => import("./../components/Admins/Add"),
                        meta: {title: "Добавление"},
                    },
                    {
                        path: ":id",
                        component: {template: "<router-view></router-view>"},
                        redirect: ":id/view",
                        meta: {title: "Администратор"},
                        children: [
                            {
                                path: "view",
                                component: () => import("./../components/Admins/View"),
                                meta: {title: "Просмотр"},
                            },
                            {
                                path: "edit",
                                component: () => import("./../components/Admins/Edit"),
                                meta: {title: "Редактирование"},
                            },
                        ],
                    },
                ],
            },
            {
                path: "chances",
                component: {template: "<router-view></router-view>"},
                redirect: "chances/list",
                meta: {title: "Правила ДУ", dontDisplayChildren: true},
                children: [
                    {
                        path: "list",
                        component: () => import("./../components/Chances/List"),
                        meta: {title: "Список"},
                    },
                    {
                        path: "add",
                        component: () => import("./../components/Chances/Add"),
                        meta: {title: "Добавление"},
                    },
                    {
                        path: ":id",
                        component: () => import("./../components/Chances/Edit"),
                        meta: {title: "Правило ДУ"},
                    },
                ],
            },
        ],
    },
    {
        path: "/login",
        component: () => import("@/components/Auth/Login"),
        meta: {
            auth: false,
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
